import { Component , useContext, useState } from "react";
import "./Card.css";
import Hand from "../../src/Hand";
import Game from "../Pages/Game/Game";
import { bufferedBuildCardID } from "../config";

function useCard(card_id, buildMode){
    if(!global.config.buildMode){
        fetch(global.config.BaseURL+"playCard/"+global.config.playerID+"/"+card_id)
        //.then(Hand.setCounter())
        .then(res => console.log(res));
        global.config.actions--;
        //const{auth} = useContext(AuthContext);
        //auth({});
    }else{
        if(global.config.bufferedBuildCardID == -1){
            global.config.bufferedBuildCardID = card_id;
            //const cardButton = ?Card.button?;
            //cardButton.style.backgroundColor = "rgba(228, 6, 6, 0.293)";
        }else{
            fetch(global.config.BaseURL+"crafting/"+global.config.playerID+"/"+card_id+"/"+global.config.bufferedBuildCardID)
            .then(request => {
                if(!request.ok){
                    alert("Ich weiß nicht wie ich das zusammenbauen soll!");
                };
        });
            global.config.bufferedBuildCardID = -1;
            global.config.actions--;
        }
    }
}

class Card extends Component{
    constructor(props) {
        super(props);
                this.state = {
                key:props.key,
                name:props.name,
                card_id: props.card_id,
                picture: props.picture,
                description: props.description,
                nahrung:props.nahrung,
                nahrungBild:props.nahrungBild,
                wasserBild:props.wasserBild,
                wasser:props.wasser,
                heilung:props.heilung,
                heilungBild:props.heilungBild,
                mental:props.mental,
                mentalBild:props.mentalBild,
                biom_id:props.biom_id,
                color:props.color
                }
    }

    render(){
        return(
        <>
        <article className="card" style={{ backgroundColor: this.state.color}}>
            <button className="cardButton" onClick={() =>{useCard(this.state.card_id); this.props.changeCounter()}}>
            <div className="innerRand">
                <header>
                    <h1>{this.state.name}</h1>
                    <img src={this.state.picture}></img>
                </header>
                <div className="changeValues">
                    <div className="imageContainer">
                        <p>{this.state.nahrung}</p>
                        <img src={this.state.nahrungBild} ></img>
                    </div>
                    <div className="imageContainer">
                        <p>{this.state.wasser}</p>
                        <img src={this.state.wasserBild} ></img>
                    </div>
                    <div className="imageContainer">
                        <p>{this.state.heilung}</p>
                        <img src={this.state.heilungBild} ></img>
                    </div>
                    <div className="imageContainer">
                        <p>{this.state.mental}</p>
                        <img src={this.state.mentalBild} ></img>
                    </div>
                </div>
                <p className="kartenDescription">{this.state.description}</p>
            </div>
            </button>
        </article>
        </>);
    }
}
export default Card;