import React from "react";
import "./Game.css";
import Handclass from "../../Hand";
//import Biome from "../../Biome/Biome";
import Biome from "../../Biome/Biome";
import { Hamburger } from "../../Hamburger_Menu/Hamburger";
import PlayerStats from "../../Pages/Player_Stats/PlayerStats";
import ButtonTable from "../../Components/ButtonTable";
import "../../TEMPLOL_.ttf";

function Game() {
    return (
        <>
            <div className="desktop">
                <Hamburger
                    top="3%"
                    right="5%"
                    backgroundColor="white"
                    listBackgroundColor="#3A4750"
                />
                <React.StrictMode>
                    <PlayerStats/>
                    <div id="Main">
                        <div id="BiomeDiv">
                            <Biome></Biome>
                        </div>
                        <div className="containerFuerHand">
                            <Handclass></Handclass>
                        </div>
                    </div>
                </React.StrictMode>
            </div>
        </>
    );
}
export default Game;
