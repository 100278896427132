import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import Startpage from './Pages/Nach_dem_Login/Startpage';
import Login from './Pages/Login/Login';
import {BrowserRouter} from 'react-router-dom';
import {AuthProvider} from './Pages/Context/AuthProvider';
import App from './App';
import './config';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <AuthProvider>
      <App></App>
    </AuthProvider>
  </BrowserRouter>
  
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
