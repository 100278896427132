import React, { useState } from "react";

/*const initialState = {
    biomeID: 1,
    playerID: 2,
    round_counter: 0,
    username:"",
    accessToken:""
}*/

export const ContextWrapper = (props) => {

    const [ store, setStore ] = useState({
        biomeID: 1,
        playerID: 2,
        round_counter: 0,
        username:"",
        accessToken:""
	});
    
    
    return(   
        <AppContext.Provider value={{store, setStore}}>
            {props.children}
        </AppContext.Provider>
    );
}

export const AppContext = React.createContext(ContextWrapper);