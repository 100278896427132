import React from "react";
import "./Hamburger.css";
import Startpage from "../Pages/Nach_dem_Login/Startpage";
import ReactDOM from 'react-dom/client';

export const Hamburger = (props) => {
    const [isOpen, setIsOpen] = React.useState(false);

    const openModal = () => setIsOpen((prevState) => !prevState);

    const saveGame = () => alert("Das Spiel wird bereits ständig gespeichert!");
    const quitGame = function loadStartpage(){
        const root = ReactDOM.createRoot(document.getElementById('root'));
        root.render(
            <Startpage></Startpage>
        );
    }

    return (
        <div className="navbar-container">
            <div
                className="hamburger-container"
                style={{
                    top: props.top,
                    right: props.right,
                    backgroundColor: props.backgroundColor,
                }}
                onClick={openModal}
            >
                <div className="hamburger"></div>
                <div className="hamburger"></div>
                <div className="hamburger"></div>
            </div>
            {isOpen && (
                <div
                    className="hamburger-list"
                    style={{
                        top: `calc(${props.top} + 5%)`,
                        right: props.right,
                        backgroundColor: props.listBackgroundColor,
                    }}
                >
                    <button onClick={saveGame} className="hambuger-list-btn">
                        Spiel Speichern
                    </button>
                    <button onClick={quitGame} className="hambuger-list-btn">
                        Spiel Verlassen
                    </button>
                </div>
            )}
        </div>
    );
};
