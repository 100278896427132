import { Route, Routes } from 'react-router-dom';
import Login from './Pages/Login/Login';
import Register from './Pages/Login/Register';
import RequireAuth from './Pages/Context/RequireAuth';
import Startpage from "./Pages/Nach_dem_Login/Startpage";
import Biome from './Biome/Biome';
import { ContextWrapper } from './Pages/Context/AppContext';


const ROLES = {
    'User' : 1
};


function App (){
    return ( 
        <ContextWrapper>
            <Routes>
            <Route path="/" element={<Login/>}>
                <Route path="login" element={<Login/>} /> 
                <Route path="register" element={<Register/>} />
                <Route element={<RequireAuth allowedRoles={[ROLES.User]} />}>
                    <Route path="/" element={<Startpage/>} />
                </Route>
            </Route>
            {/*<Route path="/" element={<Startpage/>} />*/}
        </Routes>
        </ContextWrapper>
    );
}



//<Route element={<RequireAuth allowedRoles={[ROLES.User]} />}>
//<Route path="admin" element={<Login/>} />
export default App;

//Frage: Muss ich App erst in eine Klasse umwandeln oder was wäre ein
//guter Ort für globale Variable? Einfach in index.js als JS-variable?
//