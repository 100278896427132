import React from 'react';
import Card from './Card/Card';
//import style from './Deck.module.css'
import { Wasser, Gemuese, Leben, Mental } from "./images";

export default function Deck(props) {
  function getColor(id){
    var color = '';
    switch (id) {
      case 1: //Beach
        color = '#FEEABE' 
        break;
      case 2: //Mountain
        color = '#ADA89B' 
        break;
      case 3: //Field
        color = '#B9DF51' 
        break;
      case 4: //Jungle
        color = '#5F8106' 
        break;
    
      case 5: //Sea
        color = '#82D4DD' 
        break;

      default: //
        color = '#96653E' 
        console.log("standard");
        break;
    }
      
    return color;
  } 
  return (
        props.cards.map((card) => {
         return (
          <div className='deckDerKarten'>
            <Card key={card.pivot.card_id} 
                  card_id={card.pivot.card_id}
                  name={card.name} 
                  picture={require('./images/' + card.picture)}
                  description={card.beschreibung}
                  nahrung={card.nahrung} 
                  nahrungBild={Gemuese} 
                  wasserBild={Wasser}
                  wasser={card.wasser} 
                  heilung={card.heilung}
                  heilungBild={Leben}
                  mental={card.mental}
                  mentalBild={Mental}
                  biom_id={card.biom_id} 
                  color={getColor(card.biom_id)}
                  changeCounter ={props.changeCounter}
             /> 
          </div>
         )
        })
  )
}
