import React from "react";
import "./PlayerStats.css";
import {useState, useEffect, Component} from 'react';
import { Wasser, Gemuese, Leben, Mental } from "../../images";
import "../../config";
import Startpage from "../Nach_dem_Login/Startpage";
import ReactDOM from 'react-dom/client';

function PlayerStats(){
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [loadedStats, setStats] = useState([]);
    const [actions, setActions] = useState(global.config.actions);
    useEffect(() => {getPlayerStats(); },[actions]);
    
    reloadStats();
    function getPlayerStats(){
      fetch(global.config.BaseURL+'getPlayerStats/'+global.config.playerID)
        .then(response => response.json()) //respone.json()
        .then((data) => {
            console.log(data);
            setIsLoaded(true);
            setStats(data);
            global.config.survidedDays = loadedStats.surviveDays;
            global.config.biomeID = data.biom_id;
            global.config.health = loadedStats.lebenspunkte;
            global.config.food = loadedStats.nahrung;
            global.config.water = loadedStats.wasser;
            global.config.brain = loadedStats.mental;
        },
        (error) => {
            setIsLoaded(true);
            setError(error);
            
            }
        )  
    }

    function loadStartpage(){
        const root = ReactDOM.createRoot(document.getElementById('root'));
        root.render(
            <Startpage></Startpage>
        );
    }

    function reloadStats(){
        //alert("globalActions"+global.config.actions);
        //alert("actions"+actions);
        let dead = false;
        if(global.config.actions != actions){
            setActions(global.config.actions);
            //alert("ActionsSet");
            //alert("Stats: "+loadedStats.lebenspunkte+", "+loadedStats.nahrung+", "+loadedStats.wasser+", "+loadedStats.mental);
            if(global.config.health <= 0 || global.config.food <= 0 || global.config.water <= 0 || global.config.brain <= 0){
                fetch(global.config.BaseURL+'resetPlayer/'+global.config.playerID);
                alert("Du bist gestorben!");
                getPlayerStats();
                global.config.actions = 6;
                dead = true;
                loadStartpage();
            }
            
            else if(global.config.actions <= 0){
                alert("Der Tag ist zu ende und du musst dich ausruhen...");
                fetch(global.config.BaseURL+'endTurn/'+global.config.playerID)
                .then(response => response.json())
                .then(json => {console.log(json)});
                global.config.survidedDays++;
                fetch(global.config.BaseURL+'setSurviveDays/'+global.config.playerID+'/'+global.config.survidedDays);
                if(global.config.survidedDays >=50){
                    alert("Glückwunsch: Du hast es geschafft lange genug zu überleben, um von einem Flugzeug gefunden und gerettet zu werden!\nMögest du alle verfluchen, die dich bereits aufgegeben haben!");
                    fetch(global.config.BaseURL+'resetPlayer/'+global.config.playerID);
                    dead = true;
                    loadStartpage();
                }
                global.config.actions = 6;
            }
        }
        if(!dead)setTimeout(reloadStats, 500);
    }

    if(error){
        return (
            <div> <h1>Error:{error.message}</h1></div>

            );
    }else if(isLoaded){
        return (
            <>
                <div className="container">
                    <h1 className="title">Stats</h1>
                    <div className="textStats">
                        <div className="text" id="PlayerName">{loadedStats.name}</div>
                        <div className="text" id="Actions">Aktionen: {actions}</div>
                        <div className="text" id="Days"> Überlebte Tage: {loadedStats.surviveDays}</div>
                    </div>
                    <div className="vertical">
                        <div className="card-container">
                            <div className="lg-rectangle">
                                <img className="PlayerStatsIcons" src={Leben} ></img>
                                <div className="number">{loadedStats.lebenspunkte}</div>
                            </div>
                        </div>
                        <div className="card-container">
                            <div className="lg-rectangle">
                                <img className="PlayerStatsIcons" src={Gemuese} ></img>
                                <div className="number">{loadedStats.nahrung}</div>
                            </div>
                        </div>
                        <div className="card-container">
                            <div className="lg-rectangle">
                                <img className="PlayerStatsIcons" src={Wasser} ></img>
                                <div className="number">{loadedStats.wasser}</div>
                            </div>
                        </div>
                        <div className="card-container">
                            <div className="lg-rectangle">
                                <img className="PlayerStatsIcons" src={Mental} ></img>
                                <div className="number">{loadedStats.mental}</div>
                            </div>
                        </div>
                    </div>
                    {//<button className='Refresh' onClick={reloadStats}>Reload</button>
                    }
                </div>
            </>
        )
    } else {
        return ( 
        <div>
            <div className='loading'><img src='loading.gif' alt="Loading..."/></div>
        </div>
        )
    }
};

export default PlayerStats;