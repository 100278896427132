import React, { Component } from "react";
import Handclass from "../Hand";

class ButtonTable extends Component {
    constructor() {
      super();
    }

    
  }
  export default ButtonTable;