import {useState, useEffect, Component, CurrencyContext} from 'react';
import React from 'react';
import Deck from './Deck';
import {Beach} from './images';
import ButtonTable from './Components/ButtonTable';
import "./config";
import "./Hand.css";

class Handclass extends Component{
    constructor() {
        super();
        this.state = {};
    }

    render() {
        return (
            <>
                <Hand/>
            </>
        );
    }
}

export default Handclass;

function Hand(props){
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [loadedCards, setCards] = useState([]);
    const [counter, setCounter] = useState(1);
    
    useEffect(() => {loadCardsfromDatabase()},[counter]);

    let loadCardsfromDatabase = function () {
        
        //alert ("BuildMode on load: "+buildMode);
        fetch(global.config.BaseURL+'getPlayerHand/'+global.config.playerID)
        .then(response => response.json()) //respone.json()
        .then((data) => {
            console.log(data);
            setIsLoaded(true);
            setCards(data);
        },
        (error) => {
            setIsLoaded(true);
            setError(error);
            }
        )
    }

    let incrementCounter = function (){
        setCounter(counter+1);
    }

    let sendDrawRequeset = function(){
        const request = {
        method: "GET",
        headers: {"Content-Type": "application/json"},
        //body: JSON.stringify(data)
        };
        fetch(global.config.BaseURL+'drawCardsToMaximum/'+global.config.playerID, request)
        .then(response => {response.json(); incrementCounter()})
        .then(res => console.log(res));
        global.config.actions--;
    }

    let toggleBuildMode = function(){
        const button = document.getElementById("toggleButton");
        if(global.config.buildMode){
            global.config.buildMode = false;
            global.config.bufferedBuildCardID = -1;
            button.style.backgroundColor = "rgb(170, 184, 177)";
        }
        else {
            global.config.buildMode = true;
            button.style.backgroundColor = '#96653E';
        }
    }

    if(error){
        return (
            <div> <h1>Error:{error.message}</h1></div>

            );
    }else if(isLoaded){
        return (
            <>
                <div className='handButtonContainer'>
                    <button className = "handButton" onClick={sendDrawRequeset}>Nach neuen Ressourcen suchen</button>
                    <button className = "handButton" id="toggleButton" onClick={toggleBuildMode}>Neue Items herstellen</button>
                </div>
                <div className='reiheVonHandkarten'>
                    <Deck cards={loadedCards} changeCounter = {incrementCounter}/>
                </div>
            </>
        )
    } else {
        return ( 
        <div>
            <div className='loading'><img src='loading.gif' alt="Loading..."/></div>
        </div>
        )
    }
}