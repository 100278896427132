import "./Login.css";
import ReactDOM from 'react-dom/client';
import React, { useState } from "react";
import Register from "./Register";
import Startpage from "../Nach_dem_Login/Startpage";
import {Navigate, Outlet } from "react-router-dom";
import { useContext } from "react";
import AuthContext from "../Context/AuthProvider";
//import {useNavigate} from "react-router-dom";
import "../../config";


function Login(){

    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const { auth } = useContext(AuthContext);
    //const navigate = useNavigate();
    const LOGIN_URL = '/loginPlayer';

    const token = auth?.data?.accessToken;

    const onUsernameChange = f => setUsername(f.target.value);
    const onPasswordChange = f => setPassword(f.target.value);

    function loadRegister(){
        const root = ReactDOM.createRoot(document.getElementById('root'));
        root.render(
            <Register></Register>
        );
    }

    function loadStartpage(){
        const root = ReactDOM.createRoot(document.getElementById('root'));
        root.render(
            <Startpage></Startpage>
        );
    }

    const sendData = f => {
        f.preventDefault();
        const data = {username, password};
        const request = {
            method: "POST",
            headers: {"Content-Type": "application/json"},
            body: JSON.stringify(data)
        };
        try{
            const response = fetch(global.config.BaseURL+'loginPlayer',request)
            .then( response => response.json() )
            .then( (res) => {
                    global.config.playerID = res.playerID;
                    global.config.accessToken = res.acces_token;
                    console.log(global.config.playerID);
                    console.log(global.config.accessToken);
                    //alert(global.config.playerID);
                    if(global.config.playerID > 0) loadStartpage();
                });
            
            global.config.username = username;
        }catch(err){
            alert("Error-Cath-Alert: "+err);
        }
    }

    return(
        <div className="Background">
            <div id="LoginElement">
                <form>
                    <p>Benutzername</p>
                    <input value={username} onChange={onUsernameChange} required></input>
                    <p>Passwort</p>
                    <input type="password" value={password} onChange={onPasswordChange} required></input>
                </form>
                <button type="submit" onClick={sendData}>Anmelden</button>
                <p>Noch nicht registriert?</p>
                <button onClick={loadRegister}>Registriere dich jetzt!</button>
            </div>
        </div>
    );
}
export default Login;