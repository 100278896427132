import Button from "../../Components/Button/Button";
import "./Startpage.css";
import ReactDOM from 'react-dom/client';
import Spielanleitung from "../Spielanleitung/Spielanleitung";
import Game from '../Game/Game';
import Credits from "./Credits";
import Settings from "./Settings";

function Startpage() {

    function loadGame(){
        //alert('Test!');
        const root = ReactDOM.createRoot(document.getElementById('root'));
        root.render(
        <Game></Game>
        );
    }

    function loadMenual(){
        const root = ReactDOM.createRoot(document.getElementById('root'));
        root.render(
        <Spielanleitung></Spielanleitung>
        );
    }

    function noFunction(){
        alert('This button has no function yet...');
    }

    function endGame() {
        window.location.reload();
    }

    function loadCredits() {
        const root = ReactDOM.createRoot(document.getElementById('root'));
        root.render(
            <Credits></Credits>
        );
    }

    function loadSettings() {
        const root = ReactDOM.createRoot(document.getElementById('root'));
        root.render(
            <Settings/>
        );
    }

    return (
        <div className="backGround">
            <div className="logo">Furlough Island</div>
            <div className="buttons">
                <Button name="Log-Buch" clicked={loadMenual}/>
                <Button name="Start" clicked={loadGame} />
                {/* <Button name="Spiel Laden" clicked={noFunction}/> */}
                <Button name="Einstellungen" clicked={loadSettings}/>
                <Button name="Credits" clicked={loadCredits}/>
                <Button name="Spiel beenden" clicked={endGame}/>
            </div>
        </div>
    );
}

export default Startpage;
