import { Component } from "react";
import Startpage from "./Startpage";
import ReactDOM from 'react-dom/client';
import './Credits.css';
import Button from "../../Components/Button/Button";

function Credits() {
    
    function loadStartpage(){
        const root = ReactDOM.createRoot(document.getElementById('root'));
        root.render(
            <Startpage></Startpage>
        );
    }
    
    return(
        <div id="credits-backGround" >
            <div id="credits-texts">
                <h1 className="credits-h1" >Credits</h1>
                <div className="programmer-name" >Lukas Thurner</div>
                <div className="programmer-name" >Lucas Hausner</div>
                <div className="programmer-name" >Lucas Herterich</div>
                <div className="programmer-name" >David Cymbalista</div>
                <div className="programmer-name" >Gregory Mehlhorn</div>
            </div>
            <div  className="buttons" >
                <Button name="Hauptmenü" clicked={loadStartpage} />
            </div>
        </div>
    )
}



export default Credits;