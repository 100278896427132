import "./Login.css";
import ReactDOM from 'react-dom/client';
import React, { useState } from "react";
import Startpage from "../Nach_dem_Login/Startpage";
import "../../config";
import Login from "./Login";

function Register() {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");

    const onUsernameChange = f => setUsername(f.target.value);
    const onPwdChange = f => setPassword(f.target.value);

    function sendMail(){
        alert("A Passwort has been sent to your E-Mail.");
    }

    function loadStartpage(){
        const root = ReactDOM.createRoot(document.getElementById('root'));
        root.render(
            <Startpage></Startpage>
        );
    }

    function loadLogin(){
        const root = ReactDOM.createRoot(document.getElementById('root'));
        root.render(
            <Login/>
        );
    }

    const register = f => {
        f.preventDefault();
        const data = {username, password};
        const request = {
            method: "POST",
            headers: {"Content-Type": "application/json"},
            body: JSON.stringify(data)
        };
        fetch(global.config.BaseURL+'registerPlayer', request)
        .then( response => response.json() )
            .then( (res) => {
                    global.config.playerID = res.playerID;
                    global.config.accessToken = res.acces_token;
                    console.log(global.config.playerID);
                    console.log(global.config.accessToken);
                    if(global.config.playerID > 0) loadStartpage();
                });
            
            global.config.username = username;
    }

    return(
        <div className="Background">
            <div id="LoginElement">
                <form action="/" method="GET">
                    <p>Benutzername</p>
                    <input value={username} onChange={onUsernameChange} required></input>
                    <p>Passwort</p>
                    <input type="password" value={password} onChange={onPwdChange} required></input><br></br>
                    <div id="Register-Button-Div" >
                        <button onClick={register}>Registrieren</button>
                        <button id="returnToLoginButton" onClick={loadLogin}>Zurück zum Login</button>
                    </div>
                </form>
            </div>
        </div>
    );
}
export default Register;