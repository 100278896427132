module.exports = global.config = {
    biomeID: 1,
    playerID: 0,
    round_counter: 0,
    username:"",
    accessToken:"",
    buildMode: false,
    bufferedBuildCardID: -1,
    actions: 6,
    survidedDays: 0,
    health: 100,
    food: 100,
    water: 100,
    brain: 100,
    BaseURL: "http://192.168.74.64:7000/api/"
    // other global config variables you wish
};