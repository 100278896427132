import React from "react";
import "./Spielanleitung.css";
import Startpage from "../Nach_dem_Login/Startpage";
import ReactDOM from 'react-dom/client';
import Button from "../../Components/Button/Button";

const Spielanleitung = () => {
    function loadStartpage(){
        const root = ReactDOM.createRoot(document.getElementById('root'));
        root.render(
            <Startpage></Startpage>
        );
    }
    const firstCard = (
        <>
            <h1 className="title">Spielanleitung</h1>
            <span>
                <p className="left">Vorwort:</p> <br />
                Willkommen auf der tropischen Insel Furlough island. Nach einer
                Bootsfahrt, bist du auf der Insel gestrandet. <br />
                Dein Ziel: Überleben. <br />
                Hierzu stehen dir unterschiedliche Biome zur Verfügung. Jedes
                dieser Biome hat Ressourcen die dir das Überleben erleichtern
                können, manche bewirken das Gegenteil, es gibt Dinge die können
                dir Schaden.
                <br /> Deswegen ist es wichtig einen kühlen Kopf zu bewahren und
                die richtigen Entscheidungen zu treffen
            </span>
        </>
    );

    const fourthCard = (
        <>
            <div className="pt-8 space-y-2">
                <p className="little1">
                    Nach jedem Zug treten die Effekte in Kraft:
                </p>
                <p className="little1">es verändern sich die Statuswerte.</p>
                <p className="little1">
                    Diese Statuswerte können durch ausspielen der Karten, welche
                    man in den Biomen erhält, beeinflusst werden.
                </p>
                <p className="little1">
                    Jede Karte zeigt positive und negative
                    <br /> Statusveränderungen und mögliche Effekte, welche die
                    folgenden Züge beeinflussen.
                </p>
                <p className="little1">
                    Nach jedem Zug erhält man neue Karten.{" "}
                </p>
                <p className="little1">
                    Sinkt einer der Statuswerte unter 0 ist das Spiel verloren.{" "}
                </p>
                <p className="little1">
                    Gewonnen hat der Spieler,wenn er bis Tag 30 überlebt und
                    dann der Suchtrupp ihn abholt.
                </p>
            </div>
        </>
    );

    const secondCard = (
        <>
            <p className="left pt-8">Content:</p>
            <br />
            <span className="space-y-2">
                <FeatureList
                    name="5 Biome"
                    features={[
                        "Wasser",
                        "Strand",
                        "Höhle",
                        "Dschungel",
                        "Feld",
                    ]}
                />
                <p>30 Resourcen Karten</p>
                <FeatureList
                    name="5 Statuswerte"
                    features={["Gesundheit", "Wasser", "Ernährung", "Mental"]}
                />
                <FeatureList
                    name="Effekte"
                    features={[
                        "Sonnenbrand",
                        "Verletzungen",
                        "Mangelernährung",
                        "Vergiftung (Meerwasser trinken, giftige Sachen essen)",
                        "Erschöpfung (Körperlich)",
                        "Müdigkeit (Schlaf)",
                        "Infektion",
                        "Geistige Gesundheit",
                        "Temperatur",
                    ]}
                />
            </span>
        </>
    );

    const thirdCard = (
        <>
            <p className="left pt-8">Spielablauf:</p>
            <br />
            <div className="space-y-4">
                <p className="little1">
                    Der Spieler landet auf der Insel Es gibt mehrere Statuswerte
                    die den Zustand des Spielers beschreiben.
                </p>
                <FeatureList
                    name="Züge laufen in unterschiedlichen Phasen statt"
                    features={[
                        "Speicherphase / Abwurf",
                        "Biom Auswahl",
                        "Zufall Ereignis",
                        "Karten ziehen maximal 4",
                        "Karten ausspielen",
                        "Zugende (Statuswerte verändern sich + Effekt Wirkung",
                    ]}
                    asCol
                    tabbedIn
                />
            </div>
        </>
    );

    const fithCard = (
        <>
            <p className="left pt-8">Herstellungen:</p>
            <br />
            <br />
            <br />
            <br />
            <br />
            <div>
                <p className="little1">
                    Karten können durch aufeinanderlegen kombiniert werden.
                </p>

                <p className="little1">
                    z.B.: Holzstock und Schnur wird zu Angel (siehe 2 Fisch)
                </p>

                <p className="little1">
                    Diese hergestellten Karten geben besondere Effekte die
                    längere Züge anhalten. Diese Effekte sind für das Biom in
                    dem sie ausgespielt wurden. Im Biom Hintergrund werden diese
                    Erfolge festgehalten.
                </p>
            </div>
        </>
    );

    const sixThCard = (
        <>
            <p className="left pt-8">Random Events:</p>
            <br />
            <br />
            <br />
            <p className="little1">
                Es können beim Betreten eines Biomes, Ereignisse auftreten, bei
                welchen der Spieler sich für eine Aktion entscheiden muss:
            </p>
            <FeatureList
                name=""
                features={[
                    "Umweltereignisse (Sturm)",
                    "Verfaulen",
                    "Tiere oder Gegenstände (Eichhörnchen anlocken, Orte)",
                ]}
                asCol
            />
            <div  className="buttons" >
                <Button name="Hauptmenü" clicked={loadStartpage} />
            </div>
        </>
    );

    return (
        <div className="game-grid">
            <div className="wrapper">
                <Card color="sand-2">{firstCard}</Card>
                <Card color=" ground-2">{secondCard}</Card>
            </div>
            <div className="wrapper">
                <Card color="rocks-4">{thirdCard}</Card>
                <Card color="sand-2">{fourthCard}</Card>
            </div>
            <div className="wrapper">
                <Card color="sand-1">{fithCard}</Card>
                <Card color="ground-5">{sixThCard}</Card>
            </div>
        </div>
    );
};

export default Spielanleitung;

const FeatureList = ({ name, features, asCol = false, tabbedIn = false }) => {
    return (
        <div>
            <span>{name}</span>
            <ul
                className={`${asCol ? "flex-col space-y-2" : "flex"} ${
                    tabbedIn && "card-text-indent"
                }`}
            >
                {features.map((feature, index) => (
                    <li key={index}>{feature}</li>
                ))}
            </ul>
        </div>
    );
};

const Card = ({ color, children }) => {
    return <div className={`${color} card-text`}>{children}</div>;
};
