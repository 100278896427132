import { renderMatches } from "react-router-dom";
import {useState, useEffect} from 'react';
import BiomeButton from "./BiomeButton";
import React, { Component, useContext } from "react";
import { AppContext } from "../Pages/Context/AppContext";
import { ContextWrapper } from "../Pages/Context/AppContext";
import "./BiomeButton.css";
import { Beach, Sea, Jungle, Field, Mountain} from "../images";

class Biome extends Component{
  constructor() {
      super();
      this.state = {};
  }

  render() {
      return (
          <>
              <BiomeFunction/>
          </>
      );
  }
}

export default Biome;

function BiomeFunction() {
  const { store, setStore } = useContext(AppContext);
  const [counter, setCounter] = useState(1);
  useEffect(() => {setImage()},[counter]);

  let setImage = function (){
    const image = document.getElementById("backPic");
    switch(global.config.biomeID){
      case 1: image.src = Beach; break;
      case 2: image.src = Mountain; break;
      case 3: image.src = Field; break;
      case 4: image.src = Jungle; break;
      case 5: image.src = Sea; break;
      default: image.src = Mountain; 
    }
  }

  const selectBiome = (biome_id) => {
    global.config.biomeID = biome_id;
    fetch(global.config.BaseURL+"switchBiom/"+global.config.playerID+"/"+biome_id);
    global.config.actions--;
    setCounter(counter+1);
  }

  return (
    <>
      <button onClick={() => selectBiome(1)} className="bioButtons" id="beach"  >Strand</button>
      <button onClick={() => selectBiome(5)} className="bioButtons" id="ocean" >Meer</button>
      <button onClick={() => selectBiome(3)} className="bioButtons" id="field" >Wiese</button>
      <button onClick={() => selectBiome(4)} className="bioButtons" id="jungle"  >Dschungel</button>
      <button onClick={() => selectBiome(2)} className="bioButtons" id="mountains"  >Berge</button>
      <img className="hintergrundBild" id="backPic"
      src={Beach}></img>
    </>
  )
}
