import {Navigate, Outlet } from "react-router-dom";
import { useContext } from "react";
import AuthContext from "./AuthProvider";


const RequireAuth = ({ allowedRoles }) => {
    const { auth } = useContext(AuthContext); // beobachte Kontext
    return (
    auth?.roles?.find(role => allowedRoles?.includes(role))
        ? <Outlet /> // Rendere eingebetete Route(n) im Routing
        : auth?.user
        ? <Navigate to="/unauthorized" replace /> // falsche Rolle
        : <Navigate to="/login" replace /> // nicht angemeldet
    );
}
export default RequireAuth;